/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from 'gatsby-material-ui-components'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import Call from "@material-ui/icons/Call";
import Home from "@material-ui/icons/Home";
import LocationOn from "@material-ui/icons/LocationOn";
import AccountCircle from "@material-ui/icons/AccountCircle";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Apartment from "@material-ui/icons/Apartment";
import HomeWork from "@material-ui/icons/HomeWork";
import DirectionsWalk from "@material-ui/icons/DirectionsWalk";
import BubbleChart from "@material-ui/icons/BubbleChart";
import Layers from "@material-ui/icons/Layers";


// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  header_list:{
    '& ul':{
      padding: '0 .25em 0 .25em',
      width: '100%',
      '& > li':{
        width: 'auto',
      },
    },
  },
}))

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  const cl = useStyles2();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}></ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/"
          className={classes.navLink}
          color="transparent"
          name="1"
        >
          <Home /> HOME
        </Button>
      </ListItem>
      <ListItem className={classes.listItem + " " + cl.header_list} name="2">
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="物件一覧"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={HomeWork}
          dropdownList={[
            <Link to="/chintai" className={classes.dropdownLink}>
              <Apartment className={classes.dropdownIcons} />
              賃貸物件
            </Link>,
            <Link to="/hanbai" className={classes.dropdownLink}>
              <HomeWork className={classes.dropdownIcons} />
              販売物件
            </Link>,
            <Link to="/parking" className={classes.dropdownLink}>
              <DirectionsCar className={classes.dropdownIcons} />
              貸駐車場
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem} name="3">
        <Button
          href="/other"
          className={classes.navLink}
          color="transparent"
          name="3"
        >
          <BubbleChart /> 事業案内
        </Button>
      </ListItem>
      <ListItem className={classes.listItem + " " + cl.header_list} name="4">
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="申源について"
          name="4"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Layers}
          dropdownList={[
            <Link to="/company#c1" className={classes.dropdownLink}>
              <Call className={classes.dropdownIcons} />
              お問い合わせ
            </Link>,
            <Link to="/company#c2" className={classes.dropdownLink}>
              <DirectionsWalk className={classes.dropdownIcons} />
              ご来店のご案内
            </Link>,
            <Link to="/company#c3" className={classes.dropdownLink}>
              <LocationOn className={classes.dropdownIcons} />
              会社概要
            </Link>,
            <Link to="/company#c4" className={classes.dropdownLink}>
              <AccountCircle className={classes.dropdownIcons} />
              明渡通知書
            </Link>,
            <Link to="/company#c5" className={classes.dropdownLink}>
              <DirectionsCar className={classes.dropdownIcons} />
              車庫証明
            </Link>,
          ]}
        />
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "danger",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
