import React from 'react'
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import Header from "components/Header/Header_shingen.js";
import HeaderLinks from "components/Header/HeaderLinks_shingen.js";
//import Footer from "components/Footer/Footer.js";

import Footer from './footer'


/** */
import { theme } from '../style/theme'
import { ThemeProvider } from '@material-ui/styles'


const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f0f0f0',
    '&[name="1"]':{
      '& a[name="1"]':{
        color: "inherit",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
      },
    },
    '&[name="2"]':{
      '& [name="2"]':{
        '& button':{
          color: "inherit",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        },
      },
    },
    '&[name="3"]':{
      '& a[name="3"]':{
        color: "inherit",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
      },
    },
    '&[name="4"]':{
      '& [name="4"]':{
        '& button':{
          color: "inherit",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        },
      },
    },
  },
  main:{ paddingBottom: '5em', }

}))

const Layout = ({ children, active }) => {
  const classes = useStyles()
  var ac = active
  var chengeheight = 80
  if (active == "1"){
    chengeheight = 300
  }
  return (
    <div className={classes.root} name={active}>
      <CssBaseline />
      <Header
          brand="申源"
          links={<HeaderLinks dropdownHoverColor="primary" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: chengeheight,
            color: "primary",
          }}
        />
      <ThemeProvider theme={theme}>
        <main className={classes.main}>
          {children}
        </main>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
