import React from "react";
import { Link } from 'gatsby-material-ui-components'
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
//import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import Call from "@material-ui/icons/Call";
import Mail from "@material-ui/icons/Mail";


// core components
import Button from "components/CustomButtons/Button.js";
import Warning from "components/Typography/Warning.js";
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";

import {
  primaryColor,
  warningColor,
} from "assets/jss/material-kit-pro-react.js";
const useStyles = makeStyles(styles);

// img
import logo_s from "images/logo_s.svg"
import logo_s_g from "images/logo_s_g.svg"

const Styles = makeStyles((theme) => ({
  logo_btn: {
    margin: '-0.625rem 0',
    height: '70px',
    padding: '0',
  },
  logo_s:{
    width: '12.5em',
    display: 'none',
  },
  mobileContainer:{
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  /** */
  hed_toiawase:{
    margin: '0 0 0 auto',
    fontSize: '.8em',
    textAlign: 'right',
  },
  hed_toiawase_text:{
    display: 'inline-block',
    fontSize: '1em',
    textAlign: 'left',
    marginRight: '.5em',
    verticalAlign: 'top',
    '& hr' :{
      margin: '.1em 0',
    },
    '& span':{
      '&:last-child':{
        paddingLeft: '6.5em', 
        fontSize: '.8em',
      },
    },
  },
  tel_btn:{
    padding: '5px 10px',
    marginTop: '-1em',
    '& > span > div':{
      verticalAlign: 'middle',
    },
  },
  tel_btn_icon:{
    backgroundColor: warningColor[0],
    borderRadius: '50%',
    width: 'calc(24px + .5em)',
    height: 'calc(24px + .5em)',
    textAlign: 'center',
    padding: '.2em .25em .25em .2em',
    marginRight: '.5em',
    '& svg':{
      width: '24px',
      height: '24px',
    },
    '& + div':{
      display: 'block',
      fontSize: '2em',
      fontWeight: 'bold',
      lineHeight: '1',
    },
  },
  mobile_toiawase: {
    margin: '1em 0 0 30px',
  },
  mobile_tel_btn:{
    padding: '5px 10px',
    marginLeft: '-.75em',
    marginBottom: '1em',
    '& > span > div':{
      verticalAlign: 'middle',
    },
  },
  mobile_tel_btn_icon:{
    color: '#fff',
    backgroundColor: warningColor[0],
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    marginRight: '.25em',
    '& svg':{
      width: '16px',
      height: '16px',
      margin: '0 auto',
    },
    '& + div':{
      display: 'block',
      fontSize: '1.8em',
      fontWeight: 'bold',
      lineHeight: '1',
    },
  },
  icon_btn:{
    padding: '5px 10px',
    marginTop: '-1em',
  },
  icon_btn_icon:{
    backgroundColor: warningColor[0],
    borderRadius: '50%',
    width: 'calc(24px + .5em)',
    height: 'calc(24px + .5em)',
    textAlign: 'center',
    padding: '.2em .25em .25em .2em',
    marginRight: '.5em',
    '& svg':{
      width: '24px',
      height: '24px',
    },
  },
  drawerPaper_w280:{
    width: '280px',
  },
  /** */
  show:{ display: 'block', },
  hide:{ display: 'none', },
  opacity_0:{ opacity: '0', },
  fuchi:{
  textShadow: '1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000, 0px 1px 0 #000,  0-1px 0 #000, -1px 0 0 #000, 1px 0 0 #000',
  },
  eigyoNew_h:{
    display: 'block',
    maxWidth: '195px',
    width: 'auto',
    border: 'solid 1px #ccc',
    margin: '.5em 0',
    padding: '.5em',
    fontSize: '.8em',
    '& p':{
      display: 'block',
      margin: '.25em 0 0 0',
      paddingLeft: '.5em',
      paddingTop: '.25em',
      borderTop: 'dashed 1px #ccc',
      fontWeight: 'normal',
      fontSize: '.9em',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
      },
    },
  },
}))

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  const cl = Styles();
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
      document.getElementById("logo").classList.add(cl.show);
      document.getElementById("logo_l").classList.add(cl.opacity_0);
      document.getElementById("hed").classList.add(cl.hide);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
      document.getElementById("logo").classList.remove(cl.show);
      document.getElementById("logo_l").classList.remove(cl.opacity_0);
      document.getElementById("hed").classList.remove(cl.hide);

    }
  };
  const { color, links, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container + " " + cl.mobileContainer}>
        <Button
          color="white" simple
          className={classes.title + " " + cl.logo_btn}
          href="/"
          >
            <img src={logo_s} id="logo" className={cl.logo_s} alt="有限会社 申源" />
        </Button>
        <Hidden smDown implementation="css" className={classes.hidden}>
          <div className={cl.hed_toiawase} id="hed">
            <div className={cl.hed_toiawase_text}>
              お問い合わせ　　　
              <hr />
            </div>
            <Button
              color="white" simple
              className={cl.tel_btn}
              href="tel:0532562259">
              <div className={cl.tel_btn_icon}><Call /></div>
              <Warning>0532-56-2259</Warning>
            </Button>
          </div>
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper + " " + cl.drawerPaper_w280,
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={cl.mobile_toiawase}>
            <div className={cl.hed_toiawase_text}>
              <img src={logo_s_g} className={cl.logo_s_g} alt="有限会社 申源" />
              <hr />
              <span>営業時間：月曜～金曜 9:00～17:30</span><br />
              <span>4月~11月 第2土曜 9:00～17:30</span><br/>
              <span>12月~3月 第2・4土曜 9:00～17:30</span><br/>
              <span>(土曜不定休.日曜定休)</span><br/>
            </div>
            <Button
              color="warning" simple
              className={cl.mobile_tel_btn}
              href="tel:0532562259">
              <div className={cl.mobile_tel_btn_icon}><Call /></div>
              <Warning>0532-56-2259</Warning>
            </Button>
          </div>
          <div className={classes.appResponsive}>{links}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};
