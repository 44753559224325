/* theme.js */
import { createTheme } from '@material-ui/core/styles'
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 420,
      md: 576,
      lg: 768,
      xl: 992,
      /*
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      */
    },
  },
  palette: {
    primary: { 
        light: '#6ab07c',
        main: '#20893b',
        dark: '#18672c',
        contrastText: '#ffffff',
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontFamily: '"Noto Sans JP", sans-serif',
  },
  mixins: {
    toolbar: {
      /*minHeight: 56,*/
    },  
  },
});
export default theme;